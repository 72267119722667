import { useAuth0 } from '@auth0/auth0-react';
import type { FetchOptions, FetchRequest } from 'ofetch';

import { baseFetch } from '@/lib/api-client';

export const useCustomFetch = () => {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();

  const fetchWithAccessToken = async (
    request: FetchRequest,
    fetchOptions?: FetchOptions,
  ) => {
    const res = await baseFetch(request, {
      async onRequest({ options }) {
        try {
          const accessToken = await getAccessTokenSilently();
          if (!accessToken) {
            throw new Error('Invalid access token');
          }
          options.headers.append('Authorization', `Bearer ${accessToken}`);
        } catch {
          await loginWithRedirect({
            appState: {
              returnTo: '/',
            },
          });
        }
      },
      async onResponseError({ response }) {
        if (response.status === 403) {
          await logout();
        }
      },
      ...fetchOptions,
    });
    return res;
  };

  const get = <T>(
    request: FetchRequest,
    fetchOptions?: FetchOptions,
  ): Promise<T> => fetchWithAccessToken(request, fetchOptions);

  const post = <T>(
    request: FetchRequest,
    body: FetchOptions['body'],
  ): Promise<T> => fetchWithAccessToken(request, { method: 'POST', body });

  return {
    get,
    post,
  };
};
