import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@careos/react-ui/Table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { useSessionColumns, type SessionDetail } from './session-columns';
import { SessionRequisitionsDialog } from './session-requisitions/dialog';

interface SessionDetailsProps {
  sessions: SessionDetail[];
}

export const SessionDetails = ({ sessions }: SessionDetailsProps) => {
  const columns = useSessionColumns();

  const table = useReactTable({
    data: sessions,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [open, setOpen] = useState(false);
  const [sessionId, setSessionId] = useState<string>();

  return (
    <div className="overflow-x-auto">
      <Table className="bg-slate-50">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="hover:bg-slate-50">
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              className="cursor-pointer hover:bg-slate-200"
              onClick={() => {
                setSessionId(row.original.sessionId);
                setOpen(true);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="text-sm text-gray-600">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <SessionRequisitionsDialog
          open={open}
          setOpen={setOpen}
          sessionId={sessionId}
        />
      </Table>
    </div>
  );
};

export default SessionDetails;
