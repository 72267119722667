import { Dialog } from '@careos/react-ui/Dialog';

import { SessionRequisitionsDialogContent } from './dialog-content';

type SessionRequisitionsDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  sessionId?: string;
};

export function SessionRequisitionsDialog({
  open,
  setOpen,
  sessionId,
}: SessionRequisitionsDialogProps) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {sessionId && <SessionRequisitionsDialogContent sessionId={sessionId} />}
    </Dialog>
  );
}
