import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@careos/react-ui/Table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table';
import type { Requisition } from 'careos-maestro';
import type { FetchError } from 'ofetch';
import { useTranslation } from 'react-i18next';

interface DataTableProps<TData> {
  columns: ColumnDef<TData, any>[];
  data: TData[];
  error: FetchError | null;
}

export function SessionRequisitionsDialogDataTable<TData extends Requisition>({
  columns,
  data,
  error,
}: DataTableProps<TData>) {
  const { t } = useTranslation();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const renderEmptyState = () => {
    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="h-24 text-center">
            <p className="text-red-500">{`Unexpected error: ${error.status} ${error.statusMessage}`}</p>
            <p className="text-red-500">{error.data.message}</p>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {t('requisitions.table.empty')}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <div className="min-w-fit">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="hover:bg-slate-50">
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows
            ? table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="text-sm text-gray-600">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : renderEmptyState()}
        </TableBody>
      </Table>
    </div>
  );
}
