import { createColumnHelper } from '@tanstack/react-table';
import type { Requisition } from 'careos-maestro';
import { format } from 'date-fns';

import i18n from '@/i18n';

const columnHelper = createColumnHelper<Requisition>();

export const getRequisitionColumns = () => [
  columnHelper.accessor('barcode', {
    header: i18n.t('requisitions.table.columns.barcode'),
    cell: (prop) => {
      return prop.getValue();
    },
  }),
  columnHelper.accessor('sampleType', {
    header: i18n.t('requisitions.table.columns.sampleType'),
    cell: (prop) => {
      const val = prop.getValue();
      return i18n.t(`sample_types.${val}`);
    },
  }),
  columnHelper.accessor('panelSize', {
    header: i18n.t('requisitions.table.columns.panel'),
    cell: (prop) => {
      return prop.getValue();
    },
  }),
  columnHelper.accessor('samplingKit', {
    header: i18n.t('requisitions.table.columns.samplingKit'),
    cell: (prop) => {
      const samplingKit = prop.getValue();
      if (!samplingKit) return '-';
      return i18n.t(`sampling_kits.${samplingKit}`);
    },
  }),
  columnHelper.accessor('additionalSubstances', {
    header: i18n.t('requisitions.table.columns.additionalSubstances'),
    cell: (prop) => {
      const val = prop.getValue();
      if (!val) {
        return '-';
      }

      return val.map((it) => i18n.t(it as any)).join(', ');
    },
  }),
  columnHelper.accessor('practitionerName', {
    header: i18n.t('requisitions.table.columns.practitioner'),
    cell: (prop) => {
      return prop.getValue();
    },
  }),
  columnHelper.accessor('receivedAt', {
    header: i18n.t('requisitions.table.columns.receivedAt'),
    cell: (prop) => {
      const date = new Date(prop.getValue());
      const formattedDate = format(date, 'yyyy-MM-dd HH:mm');
      return <label className="text-gray-400">{formattedDate}</label>;
    },
  }),
];
