import type {
  SamplingKitIdentifier,
  ToxicologyActivityIdentifier,
} from '@careos/identifiers';
import { PanelSize } from '@careos/toxicology-types';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface SessionDetail {
  createdAt: string;
  sessionId: string;
  samplingDate: string;
  sampleCount?: number;
  sampleTypes: ToxicologyActivityIdentifier[];
  panelSize: PanelSize[];
  samplingKits: SamplingKitIdentifier[];
  additionalSubstances: string[];
  practitioner: string;
}

const columnHelper = createColumnHelper<SessionDetail>();
const NO_DATA = '-';

const joinArrayOrDefault = <T,>(array: T[]) =>
  array.length ? array.join(', ') : NO_DATA;

export const useSessionColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      columnHelper.accessor('sessionId', {
        header: t('table.session_details.session_ID'),
        cell: ({ getValue }) => {
          const value = getValue();
          return value.slice(0, 6);
        },
      }),

      columnHelper.accessor('samplingDate', {
        header: t('table.session_details.sampling_date'),
      }),
      columnHelper.accessor('sampleCount', {
        header: t('table.session_details.sample_count'),
      }),
      columnHelper.accessor('sampleTypes', {
        header: t('table.session_details.sample_types'),
        cell: ({ getValue }) => {
          const sampleTypes = getValue();
          return sampleTypes
            .map((sampleType) => t(`sample_types.${sampleType}`))
            .join(', ');
        },
      }),
      columnHelper.accessor('samplingKits', {
        header: t('table.session_details.sampling_kit'),
        cell: ({ getValue }) => {
          const samplingKits = getValue().map((it) => t(`sampling_kits.${it}`));
          return joinArrayOrDefault(samplingKits);
        },
      }),
      columnHelper.accessor('panelSize', {
        header: t('table.session_details.panel_size'),
        cell: ({ getValue }) => {
          const panelSizes = getValue().map((it) => t(`panel.${it}`));
          return joinArrayOrDefault(panelSizes);
        },
      }),
      columnHelper.accessor('additionalSubstances', {
        header: t('table.session_details.additional_substances'),
        cell: ({ getValue }) => {
          const substances = getValue();
          if (!substances?.length) return NO_DATA;
          return substances
            .map((substance: string) => t(substance as any))
            .join(', ');
        },
      }),
      columnHelper.accessor('practitioner', {
        header: t('table.session_details.collector'),
      }),
      columnHelper.accessor('createdAt', {
        header: t('table.session_details.created_at'),
        cell: ({ getValue }) => {
          return <label className="text-gray-400">{getValue()}</label>;
        },
      }),
    ],
    [t],
  );
};
