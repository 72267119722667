import {
  ReasonSchema,
  ToxicologySamplingDetailsSchema,
  ToxicologySamplingSessionDetailsSchema,
} from '@careos/toxicology-types';
import { z } from 'zod';
import { OrderStatus } from '../order-status';
import { KeyNameSchema } from '@careos/types';

export const RequesterSchema = KeyNameSchema.extend({
  workplace: z.string(),
});

export const SamplingSessionSchema = z.object({
  sessionId: z.string(),
  createdAt: z.string(),
  samplingDate: z.string(),
  timeSlot: z.string(),
  practitioner: z.string(),
  sampleCount: z.number().optional(),
  samplingDetails: z.array(ToxicologySamplingSessionDetailsSchema).min(1),
});
export type SamplingSession = z.infer<typeof SamplingSessionSchema>;

export const WorkplaceTestingOrderSchema = z.object({
  orderId: z.string(),
  orderStatus: z.nativeEnum(OrderStatus),
  requester: RequesterSchema,
  orderDate: z.string(),
  reasonForTesting: ReasonSchema,
  samplingPeriod: z.object({
    start: z.string(),
    end: z.string(),
  }),
  samplingTimeSlot: z.string().nullish(),
  notes: z.string().nullish(),
  samplingDetails: z.array(ToxicologySamplingDetailsSchema).min(1),
  sessions: SamplingSessionSchema.array().nullish(),
});
export type WorkplaceTestingOrder = z.infer<typeof WorkplaceTestingOrderSchema>;

export const GetWorkplaceTestingOrdersResponseDtoSchema = z.object({
  workplaceTestingOrders: WorkplaceTestingOrderSchema.array(),
});
export type GetWorkplaceTestingOrdersResponseDto = z.infer<
  typeof GetWorkplaceTestingOrdersResponseDtoSchema
>;
